<template>
  <MoleculeEmpty
    header="404"
    :title="$t('pages.404.title')"
    :message="$t('pages.404.message')"
  >
    <AtomButton
      to="/"
      color="primary"
    >
      <arrow-left-icon />{{ $t('action.back_home') }}
    </AtomButton>
  </MoleculeEmpty>
</template>

<script>
import MoleculeEmpty from '@/components/MoleculeEmpty'
import AtomButton from '@/components/AtomButton'

export default {
  title: vm => vm.$t('pages.404.title'),

  components: {
    MoleculeEmpty,
    AtomButton,
  },
}
</script>
